<template>
  <InvitePage :title="title">
    <InlineLoader v-if="isLoading" size="large" />

    <div v-else>
      <Message v-if="notFound" type="secondary" :message="$t('invite.noInviteFound')" />
      <Message v-else-if="hasAccepted" type="secondary" :message="$t('invite.userAcceptedCalendarEvent')" />
      <Message v-else-if="hasDeclined" type="secondary" :message="$t('invite.userDeclinedCalendarEvent')" />
      <Message v-else-if="hasAlreadyAnsweredInvite" type="secondary" :message="$t('invite.alreadyAnswered')" />

      <div v-else>
        <InviteHeader
          :where="invite.huntArea"
          :starts-at="invite.startsAt"
          :ends-at="invite.endsAt">
        </InviteHeader>

        <Divider />

        <Paragraph class="content text-is-preformatted" v-html="invite.description" />

        <Divider />

        <Heading size="5" :text="$t('general.invited')" />

        <InviteMember
          v-for="member in invite.invitations"
          :key="member.userId"
          :user-id="member.userId"
          :name="member.name"
          :badge="memberBadge(member)"
          :badge-type="memberBadgeType(member)">
        </InviteMember>

        <Divider />

        <ButtonGroup>
          <InviteAcceptButton
            :loading="isAccepting"
            :disabled="isDeclining"
            @click="respond(true)">
          </InviteAcceptButton>

          <InviteDeclineButton
            :loading="isDeclining"
            :disabled="isAccepting"
            @click="respond(false)">
          </InviteDeclineButton>
        </ButtonGroup>
      </div>
    </div>
  </InvitePage>
</template>

<script>
import { mapGetters } from 'vuex'

import InvitePage from './InvitePage.vue'
import InviteHeader from './InviteHeader.vue'
import InviteMember from './InviteMember.vue'
import InviteAcceptButton from './InviteAcceptButton.vue'
import InviteDeclineButton from './InviteDeclineButton.vue'

import {
  STATUS_LOADING,
  STATUS_ACCEPTING,
  STATUS_DECLINING,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_NOT_FOUND,
  STATUS_ALREADY_ANSWERED
} from '../store/inviteStatus'

export default {
  components: {
    InvitePage,
    InviteHeader,
    InviteMember,
    InviteAcceptButton,
    InviteDeclineButton
  },

  computed: {
    ...mapGetters({
      invite: 'invites/activity/getInvite',
      status: 'invites/activity/getStatus'
    }),

    title () {
      return this.invite !== null ? this.$i18n.t('invite.title', { name: this.invite.title }) : ''
    },

    isLoading () {
      return this.status === STATUS_LOADING
    },

    notFound () {
      return this.status === STATUS_NOT_FOUND
    },

    isAccepting () {
      return this.status === STATUS_ACCEPTING
    },

    isDeclining () {
      return this.status === STATUS_DECLINING
    },

    hasAccepted () {
      return this.status === STATUS_ACCEPTED
    },

    hasDeclined () {
      return this.status === STATUS_DECLINED
    },

    hasAlreadyAnsweredInvite () {
      return this.status === STATUS_ALREADY_ANSWERED
    }
  },

  created () {
    this.$store.dispatch('invites/activity/readByToken', this.$route.params.token)
  },

  methods: {
    memberBadge (member) {
      if (member.hasAnswered) {
        return member.hasAccepted ? this.$i18n.t('invite.accepted') : this.$i18n.t('invite.declined')
      }

      return this.$i18n.t('invite.notAnswered')
    },

    memberBadgeType (member) {
      if (member.hasAnswered) {
        return member.hasAccepted ? 'success' : 'danger'
      }

      return ''
    },

    async respond (answer) {
      try {
        await this.$store.dispatch('invites/activity/answerInvite', answer)

        setTimeout(() => {
          this.close()
        }, 5000)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('invite.unknownErrorMessage'))
      }
    },

    close () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
ul.member-list {
  list-style: disc inside;
}

ul.member-list li {
  margin-bottom: 0.5rem;
}
</style>
